
import { MenuBar } from '../components/MenuBar'
import { Content } from '../components/Content'
import { Footer } from '../components/Footer'
import MetaTags from '../components/MetaTags'
import { Cookies } from '../components/Cookies'
import { getHeaderQuery } from '../lib/strapi/queries'
import { IS_PRODUCTION } from '../config'
import { Header } from '../lib/strapi/responseTypes'
import { GetStaticProps } from 'next'
import { useRouter } from 'next/router'

type BasicPageData = { headerData: Header }

export default function PageMissing({headerData}: BasicPageData) {

  const { asPath } = useRouter();
  
  return (
    <>
      <MetaTags pageTitle={'404: This page cannot be found - Protect Our Winters UK'} />
      <Cookies />
      <MenuBar currentPage={asPath} menuItems={headerData.data.attributes.menuItems} />

      <Content>
        {/* import ErrorPage from 'next/error'
             <ErrorPage statusCode={404}/> */}
        <div className="flex justify-center items-center gap-x-4 mt-24 mb-10">
          <span className="text-3xl tracking-widest">404</span>
          <span className="border-l h-12">&nbsp;</span>
          <span className="uppercase tracking-wide">This page could not be found</span>
        </div>
      </Content>

      <Footer />
    </>
  )
}

export const getStaticProps: GetStaticProps<BasicPageData> =
  async () => {

    const headerData = await getHeaderQuery({
      showPreview: !IS_PRODUCTION,
    })

    return {
      props: { headerData: headerData.header },
    }
  }
